import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WhatWeDo from "../components/WhatWeDo"

const AboutUsPage = () => (
  <Layout>
    <SEO title="About Us" />
    <WhatWeDo />
  </Layout>
)

export default AboutUsPage
